.App {
  text-align: center;
  background-color: rgb(186, 196, 215);
  max-width: 1200px;
  /*min-height: 1200px;*/
  border: solid 1px black;
  box-shadow: 1px 1px 1px black;
  margin-top: 20px;
  margin:10px;
  border-radius: 0%;
  padding: 20px;
}

h3.MuiTypography-h3{
  font-size: 40px;
}

/*I use styles always from here. i think its much more clear than mixed in components code.*/
.headliner{
  background-color: rgb(186, 196, 215);
  border: solid 2px black;
  text-shadow: 2px 2px 2px;
  border-radius: 2%;
  box-shadow: 1px 1px 1px black;
  margin-top:10px;
  margin:10px;
  padding: 10px;
  
}

.list_box{
  margin-top: 10px;
  opacity: 20;
  
  padding:5px;
  
  
}
.DialogContainer{
  width: 100%;
  display:relative;
  align-items: center;
  
  background-color: aquamarine;
}

.feedbackDialog{
  width : 40%;
  min-height: 20%;
  
  align-items: center;
}

.mobileView{
  display:none;
}

.toolbar{
  margin:10px;
  background-color: rgb(186, 196, 215);
  border: solid 2px black;
  margin-bottom: 10px;
  /*box-shadow: 1px 1px 1px black;*/
  border-radius: 1%;
 
}

.start_image{
  margin-top: 10px;
  background-image: url('./photos/img_start.jpg');
  background-repeat: repeat;
  background-size: 100% cover;
  background-position: center;
  margin:10px;
  width:100%;
  min-height:1100px;
  
  border-radius: 1%;
  box-shadow: 1px 1px 1px black;
}
.StartInfo{
  margin: 20px;
  padding: 20px;
  align-items: center;
  width: 100%;
  height: auto;
}
.startpageList{
  
  width:100%;
  background-color: rgba(211, 211, 211, 0.80);
  margin:5px;
  margin-top: 10px;
  border-radius: 25px;
  height: auto;
}

.forecast{
  margin-top: 10px;
  background-image: url('./photos/forecastedited.png');
  background-repeat: repeat;
  background-size: 100% cover;
  background-position: top;
  opacity: 2;
  background-color: rgb(128, 133, 133);
  min-width: auto;
  min-height: 1000px;
  /*box-shadow: 1px 1px 1px black;*/
  color:rgb(252, 247, 247);
}

.workingtime{
  margin-top: 10px;
  background-image: url('./photos/working.jpg');
  background-repeat: repeat;
  background-size: 100% cover;
  /*background-position: center;*/
  min-width: auto;
  min-height: 1000px;
  border-radius: 1%;
  box-shadow: 1px 1px 1px black;
}

.worktimeFields{
  margin-top:10px;
  margin-bottom: 10px;
}

.listViewItems{
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: left;
  border:2px solid black;
  box-shadow: 1px 1px 1px black;
  border-radius: 1%;
  margin:8px;
  color:rgb(0, 0, 0);
 
}

.listViewItemsNews{
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: left;
  border:2px solid black;
  box-shadow: 1px 1px 1px black;
  border-radius: 1%;
  margin:8px;
  color:rgb(255, 255, 255);
 
}

.listViewItemsWorkTime{
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: left;
  border:2px solid black;
  box-shadow: 1px 1px 1px black;
  border-radius: 3px;
  margin:8px;
  color:rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.7);

}

.projects{
  display:flex;
  justify-content: center;
  margin-top: 10px;
  background-image: url('./photos/projects.jpg');
  background-repeat: repeat;
  background-size: 100% contain;
  background-position: center;
  max-width: 100%;
  min-height: 1000px;
  border-radius: 1%;
  box-shadow: 1px 1px 1px black;
  align-items: center;

}
.middleBoxProjects{
  display:flex;
  justify-content: space-between;
  margin-top: 50px;
  
  width:100%;
  padding: 5px;
  height: auto;
}
.projectsPaper{
  align-items: top;
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: row;
  columns: 2;
  justify-content: space-between;
  margin:20px;
}
.projectsCard{
  margin-top: 5px;
  width:38%;
  height: auto;
  margin: 10px;
}
.endBoxProjects{
       
    margin-top: 60px;
    margin:10px;
    padding: 10px;
    width: 70%;
    height: auto;
  

}

.Aboutme{
  margin-top: 10px;
  background-image: url('./photos/aboutme.jpg');
  background-repeat: repeat;
  background-size: 100% cover;
  background-position: top;
  min-width: auto;
  min-height: 1000px;
  border-radius: 1%;
  box-shadow: 1px 1px 1px black;
  padding:10px;
  margin: 10px;
}

.projectDialog{
  max-width:1000px;
  max-height: 1000px;
  background-color: black;
}

.projectButtons{
  margin:5px;
  box-shadow: 3px 3px 3px black;
}

.divTag{
  margin: 2px;
  text-align: justify;
  background-color: antiquewhite;
  
}
.div_tag{
  padding : 10px
}

h2{
  background-color: antiquewhite;
}

.AboutMeBox{
  min-width: 200px;
  min-height: 200px;
  float:left;
  text-align: left;
  color:rgb(240, 240, 240);
}

.profilePic{
  bottom: 10px;
  float:right;
}

.news_page{/*page is resizing wrong. Figure out, what is the problem. */
  width:100%;
  min-height:1000px;
  min-width: auto;
  height: auto;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Links{
  font-size: 15px;
  max-width:100%;
}
.news_image{
  max-width: 30%;
}

.news_page_cont{
  background-image: url('./photos/news_background_edited.png');
  background-repeat: repeat;
  background-size: 100% cover;
  background-position: top;
  margin-top: 10px;
  background-color: rgb(154, 159, 163);
  min-width: auto;
  min-height: 1000px;
  box-shadow: 1px 1px 1px black;
  color:white;
 
  
}
.Portfolioontainer{
  margin-top: 10px;
  background-color: rgb(229, 230, 230);
  padding: 10px;
  width:100%;
  min-height:1000px;
  min-width: auto;
  height: auto;
  border: 1px;
  border-color: black;

}


.portfolioPaper{
  
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px;
  background-color: cadetblue;
  padding:15px;
  border: 1px;
  border-color: black;
}

.portfolioCard {
  
  width: 50%;
  padding:25px;
  background-color: aliceblue;
  border: 1px;
  border-color: black;
  margin:15px;
}
.portfolioImageExpandDialog{
  width:100%;
  height: 100%;
}
.portfolioCardmedia{
  width:100%;
  padding:2ps;
}
img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.appFooter{
  width:1200px;
  min-height:90px;
  background-color:  rgb(186, 196, 215);
  border: 1px solid black;
  box-shadow: 1px 1px 1px black;
}
.footerBox{
  width:100%;
  min-height: 170px;
  display:flex;
  flex-direction: row;
  
  margin-bottom: 7px ;

}
.footerLinkGroup{
  display:flex;
  flex-direction: column;
  
}
.footerLinkGroupSecond{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
 
}
.sosialMediaLinks{
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin:10px;
  width:40%;
}
@media only screen and (max-width:690px) {
  h3.MuiTypography-h3{
    font-size: 1.6rem;
  }
  .list_box{
    /*display:none;*/
  }
  .portfolioPaper{
    width: 100%;
    display: flex;
    margin: 5px;
    flex-direction: column;
  }
  .portfolioCard {
    width: 95%;
    margin:10px;
    
    
  }
  .projectsPaper{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .projectsCard{
    width:90%;
    height: auto;
    margin: 10px;
  }
  
  .mobileView{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin:2px;
    padding:5px;
  }
  .AboutMeBox{
    color:rgb(245, 19, 234);
    text-align: justify;
  }
  .feedbackDialog{
    width : 100%;
    min-height: 20%;
    
    align-items: center;
  }
  

}

